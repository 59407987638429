import React from 'react'

import { IconButton } from '../../../../../components/icon-button'
import { CloseXIcon } from '../icons'
import { Modal } from '../modal'
import styles from './modal-info-template.module.css'

export const ModalInfoTemplate = (props) => {
  const { children } = props

  return (
    <Modal {...props}>
      <div className={styles.modal}>
        <div className={styles.template}>
          <div className={styles.close}>
            <IconButton onClick={() => props.hideModal()}>
              <CloseXIcon />
            </IconButton>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </Modal>
  )
}
