import React from 'react'
import infoOrange from './info-orange.svg'

import weightGain from './weight-gain.svg'
import maintenance from './maintenance.svg'
import maintenanceProgram from './maintenance-program.svg'
import weightLoss from './weight-loss.svg'
import lunchAndDinner from './lunch-and-dinner.svg'

import people1 from './people-1.svg'
import people2 from './people-2.svg'
import people3 from './people-3.svg'
import people5 from './people-5.svg'
import people1Active from './people-1-active.svg'
import people2Active from './people-2-active.svg'
import people3Active from './people-3-active.svg'

import male from './male.svg'
import female from './female.svg'

import meat from './meat.svg'
import vege from './vege.svg'
import bezRib from './bezRib.svg'
import checkRadioWhiteOn from './check-radio-white-on.svg'
import checkRadioGreenOn from './check-radio-green-on.svg'

import appleGreen from './apple-green.svg'
import appleGrey from './apple-grey.svg'
import chickenGreen from './chicken-green.svg'
import chickenGrey from './chicken-grey.svg'
import croissantGreen from './croissant-green.svg'
import croissantGrey from './croissant-grey.svg'
import pearGreen from './pear-green.svg'
import pearGrey from './pear-grey.svg'
import saladGreen from './salad-green.svg'
import saladGrey from './salad-grey.svg'

import closeX from './close-x.svg'

import promo from './promo.svg'
import promoSuccess from './promo-success.svg'
import promoFail from './promo-fail.svg'

import user from './user.svg'

import backArrow from './back-arrow.svg'

import calendar from './calendar.svg'
import time from './time.svg'

import calendarSuccess from './calendar-success.svg'
import timeSuccess from './time-success.svg'

import successStatus from './success-status.svg'
import failedStatus from './failed-status.svg'
import dropdownArrow from './dropdown-arrow.svg'
import logoIcon from './logo.svg'

import persPayInfo from './personal-and-payment-info.svg'
import order from './order.svg'

export const OrderIcon = () => {
  return <img src={order} alt="" />
}

export const LogoIcon = () => {
  return <img src={logoIcon} alt="" />
}

export const PersPayInfo = () => {
  return <img src={persPayInfo} alt="" />
}

export const InfoOrangeIcon = ({ children, selected }) => {
  return <img src={infoOrange} alt="" />
}

export const WeightGainIcon = ({ children, selected }) => {
  return <img src={weightGain} alt="" />
}

export const LunchAndDinnerIcon = ({ children, selected }) => {
  return <img src={lunchAndDinner} alt="" />
}

export const MaintenanceIcon = ({ children, selected }) => {
  return <img src={maintenance} alt="" />
}

export const MaintenanceProgramIcon = ({ children, selected }) => {
  return <img src={maintenanceProgram} alt="" />
}

export const WeightLossIcon = ({ children, selected }) => {
  return <img src={weightLoss} alt="" />
}

export const People1Icon = ({ children, selected }) => {
  return <img src={people1} alt="" />
}

export const People2Icon = ({ children, selected }) => {
  return <img src={people2} alt="" />
}

export const People3Icon = ({ children, selected }) => {
  return <img src={people3} alt="" />
}

export const People5Icon = ({ children, selected }) => {
  return <img src={people5} alt="" />
}

export const People1ActiveIcon = ({ children, selected }) => {
  return <img src={people1Active} alt="" />
}

export const People2ActiveIcon = ({ children, selected }) => {
  return <img src={people2Active} alt="" />
}

export const People3ActiveIcon = ({ children, selected }) => {
  return <img src={people3Active} alt="" />
}

export const MaleIcon = ({ children, selected }) => {
  return <img src={male} alt="" />
}

export const FemaleIcon = ({ children, selected }) => {
  return <img src={female} alt="" />
}

export const MeatIcon = ({ children, selected }) => {
  return <img src={meat} alt="" />
}

export const VegeIcon = ({ children, selected }) => {
  return <img src={vege} alt="" />
}

export const BezRibIcon = ({ children, selected }) => {
  return <img src={bezRib} alt="" />
}

export const CheckRadioWhiteOnIcon = ({ width = 16 }) => {
  return <img src={checkRadioWhiteOn} width={width} alt="" />
}

export const CheckRadioGreenOnIcon = () => {
  return <img src={checkRadioGreenOn} alt="" />
}

export const CheckRadioOffIcon = ({ width = 16 }) => {
  return (
    <span
      style={{
        display: 'inline-flex',
        height: 16,
        width,
        background: '#fff',
        border: '1px solid #87A5C0',
        'border-radius': '50%',
      }}
    />
  )
}

export const BreakfastIcon = () => {
  return <img src={croissantGrey} alt="" />
}

export const BreakfastActiveIcon = () => {
  return <img src={croissantGreen} alt="" />
}

export const LunchIcon = () => {
  return <img src={chickenGrey} alt="" />
}

export const LunchActiveIcon = () => {
  return <img src={chickenGreen} alt="" />
}

export const Snack1Icon = () => {
  return <img src={appleGrey} alt="" />
}

export const Snack1ActiveIcon = () => {
  return <img src={appleGreen} alt="" />
}

export const Snack2Icon = () => {
  return <img src={pearGrey} alt="" />
}

export const Snack2ActiveIcon = () => {
  return <img src={pearGreen} alt="" />
}

export const DinnerIcon = () => {
  return <img src={saladGrey} alt="" />
}

export const DinnerActiveIcon = () => {
  return <img src={saladGreen} alt="" />
}

export const CloseXIcon = () => {
  return <img src={closeX} alt="" />
}

export const PromoIcon = () => {
  return <img src={promo} alt="" />
}

export const PromoSuccessIcon = () => {
  return <img src={promoSuccess} alt="" />
}

export const PromoFailIcon = () => {
  return <img src={promoFail} alt="" />
}

export const UserIcon = () => {
  return <img src={user} alt="" />
}

export const BackArrowIcon = () => {
  return <img src={backArrow} alt="" />
}

export const CalendarIcon = () => {
  return <img src={calendar} alt="" />
}

export const TimeIcon = () => {
  return <img src={time} alt="" />
}

export const CalendarSuccessIcon = () => {
  return <img src={calendarSuccess} alt="" />
}

export const TimeSuccessIcon = () => {
  return <img src={timeSuccess} alt="" />
}

export const SuccessStatusIcon = () => {
  return <img src={successStatus} alt="" />
}

export const FailedStatusIcon = () => {
  return <img src={failedStatus} alt="" />
}

export const DropdownArrowIcon = () => {
  return <img src={dropdownArrow} alt="" />
}
