import cx from 'classnames'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHomepageTabsContext } from '../../contexts/HomepageTabsContext'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'
import { Button2 } from '../button2'
import { Container } from '../container'
import styles from './hero3-new.module.css'

export const Hero3New = () => {
  const scroll = useSmoothScroll()
  const { activeTab, dispatchAction } = useHomepageTabsContext()
  const { t } = useTranslation()

  const openCalcForm = selector => {
    dispatchAction({ type: 'OPEN_TAB1' })
    scroll.animateScroll(document.getElementById(selector))
  }

  // const openOrderForm = selector => {
  //   dispatchAction({ type: 'OPEN_TAB2' })
  //   scroll.animateScroll(document.getElementById(selector))
  // }

  const scrollToReviewsSection = selector => {
    scroll.animateScroll(document.getElementById(selector))
  }

  const isEn = document.location.pathname.includes('/en')

  const calculateBtn = () => {

    const userData = localStorage.getItem('order-food-contact-information');
    const data = JSON.parse(userData);

    const name = data?.name;
    const phone = data?.phone;
    const email = data?.email;
    const zip = data?.deliveryAddress?.components?.postcode ? data?.deliveryAddress?.components?.postcode : '';
    const city = data?.city?.label ? data?.city?.label : '';
    const state = 'CZ';

    let firstName = '';
    let lastName = '';

    if (name) {
      const nameParts = name.split(' ');
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' ');
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state
    };

    // setTimeout(() => {
    //   window.dataLayer.push({
    //     event: 'click_hp_calculate_price',
    //     pageURL: isEn ? '/en/thank-you' : '/thank-you',
    //     pageType: 'Purchase',
    //     userInfo,
    //   });

    // }, 300);
  };

  const getOrderPage = ()=>{
    window.location.href = 'https://panel.nutritionpro.pl/pl/new-order/create?calorific=7&diet=1&ecoContainers=false&variant=1&menuPremium=false&step=wybierz-diete'
  }

  return (
    <>
      <section className={styles.heroBanner} onClick={getOrderPage} >
      <Container isWide={true}>
        <div
          className={cx(styles.buttons, styles.buttonWrapperMobile, styles.heroBannerBtns)}
          id="sticky-buttons"
          onClick={() => calculateBtn()}
        >
          <Button2
            color="primary"
            className={styles.button}
            handleClick={e => {
              openCalcForm('calculator2')
            }}
          >
            <svg
              className={styles.buttonIcon}
              fill="none"
              height="25"
              viewBox="0 0 25 25"
              width="25"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m13.5413 15.625h2.0834m-10.41669 6.25v-16.66667c0-.55253.21949-1.08244.61019-1.47314s.92061-.61019 1.47314-.61019h10.41666c.5525 0 1.0824.21949 1.4731.61019s.6102.92061.6102 1.47314v16.66667l-3.125-2.0833-2.0833 2.0833-2.0833-2.0833-2.0834 2.0833-2.08329-2.0833zm4.16666-14.58333h6.25003zm0 4.16663h6.25003z"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5625"
              />
            </svg>
            Dobierz dietę
          </Button2>
          <a
            style={{ color: '#fff' }}
            href='https://panel.nutritionpro.pl/pl/new-order/create?calorific=7&diet=1&ecoContainers=false&variant=1&menuPremium=false&step=wybierz-diete'
            // target='_blank'
          >
            <Button2 className={styles.button} color="secondary">
              Zamówienie online
            </Button2>
          </a>
        </div>
      </Container>
      </section>
    </>

  )
}
