import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Container } from '../container'
import styles from './weDeliverInPolish.module.css'
import map from './PL map.svg'
import icon1 from './icon1.svg'
import icon2 from './icon2.svg'
import icon3 from './icon3.svg'
import icon4 from './icon4.svg'

export const WeDeliverInPolish = ({ id }) => {
  const { t } = useTranslation()
  const isEn = document.location.pathname.includes('/en')

  return (
    <section id={id && id} className={styles.delivery}>
      <Container isWide={true}>
        <h3
          className="fancyUnderlineText sectionTitleNew text-center"
          style={{ marginBottom: 0 }}
        >
          <Trans i18nKey="home.polishDeliveryTitle">
            Dostarczamy <span> na terenie całej Polski</span>
          </Trans>
        </h3>
        <p className={styles.deliveryText}>{t('home.polishDeliveryText')}</p>

        <div className={styles.deliveryWrap}>
          <div className={styles.deliveryMap}>
            <img src={map} alt="map" />
          </div>

          <div className={styles.deliveryBox}>
            <div className={styles.iconBox}>
              <img src={icon1} alt="..." />
              <p className={styles.deliveryText}>
                Dostawa  <br /> jedzenia codziennie
              </p>
            </div>

            <div className={styles.iconBox}>
              <img src={icon2} alt="..." />
              <p className={styles.deliveryText}>
                Dostawy realizujemy w godzinach 02:00-09:00{' '}
              </p>
            </div>

            <div className={styles.iconBox}>
              <img src={icon3} alt="..." />
              <p className={styles.deliveryText}>
                Dostawa jest zawsze bezpłatna
              </p>
            </div>

            <div className={styles.iconBox}>
              <img src={icon4} alt="..." />
              <p className={styles.deliveryText}>
                Dostawa jedzenia na terenie całej Polski
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
