import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './new-calculator-blocks.module.css'
import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'
import img5 from './images/5.png'
import img6 from './images/6.png'
import img7 from './images/7.jpg'
import img8 from './images/8.png'
import img9 from './images/9.png'

import img10 from './images/10.png'
import img11 from './images/11.jpg'

import arrowImg from './images/arrow.svg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Container } from '../container'
import { NewFoodCardCalendar } from '../new-food-card-calendar'
const slidesData = [
  {
    img: img11,
    title: 'DIETA Z WYBOREM MENU',
    price: '76,95 zł',
    jidel: '5 posiłków',
  },
  {
    img: img3,
    title: 'Standard',
    param1: '329 kcal',
    param2: '(6 694 kJ)',
    param3: '(6 694 kJ)',
    price: '63,95 zł',
    type:
      'Zdrowa i w pełni zbilansowana dieta, bogata w niezbędne składniki mineralne, witaminy i błonnik pokarmowy. Opiera się na : świeżych warzywach i owocach; pełnoziarnistych produktach zbożowych; nabiale; chudym mięsie; rybach. Wzbogacana regularnie o sezonowe składniki, które cieszą się najlepszymi ocenami wśród Klientów. Komponując naszą kluczową dietę, posiłkujemy się wiedzą i badaniami zgodnymi z normami żywienia regulowanymi przez Instytut Żywności i Żywienia, pod okiem naszych Dietetyków. Dieta podzielona jest na warianty kaloryczne, które w zależności od założonego celu dietetycznego, pozwolą utrzymać aktualną masę ciała lub smacznie i zdrowo zredukować masę ciała.',
    jidel: '5 posiłków',
  },
  {
    img: img1,
    title: 'Vege',
    param1: '329 kcal',
    param2: '(6 694 kJ)',
    param3: '180g',
    price: '63,95 zł',
    type:
      'Dieta skierowana do osób, która wykluczają spożywanie mięs i ryb w swoim jadłospisie. Składa się głownie z produktów zbożowych , nasion i roślin strączkowych , świeżych warzyw i owoców , produktów mlecznych , jaj i zdrowych tłuszczy. Dieta dostarcza organizmowi potrzebnych witamin, składników mineralnych oraz błonnika pokarmowego.',
    jidel: '5 posiłków',
  },
  {
    img: img4,
    title: 'Active',
    param1: '329 kcal',
    param2: '(6 694 kJ)',
    param3: '180g',
    price: '79,95 zł',
    type:
      'Nutrition Activ to dieta skierowana do osób aktywnych, uprawiających sport, ze zwiększonym zapotrzebowaniem na energię oraz podaż składników pokarmowych. Zbilansowane przez nas posiłki zawierają zwiększone ilości białka przy obniżonej zawartości tłuszczu. Jest to idealna propozycja dla osób aktywnych fizycznie.',
    jidel: '6 posiłków',
  },
  // {
  //   img: img2,
  //   title: 'Office',
  //   param1: '329 kcal',
  //   param2: '(6 694 kJ)',
  //   param3: '180g',
  //   price: '56.95 zł',
  //   type:
  //     'Zestaw składa się z 3 posiłków - śniadanie , obiad i podwieczorek. Przygotowany z myślą o zapewnieniu zdrowego odżywiania w pracy.',
  //   jidel: '3 posiłków',
  // },
  // {
  //   img: img5,
  //   title: 'Office Vege',
  //   param1: '329 kcal',
  //   param2: '(6 694 kJ)',
  //   param3: '180g',
  //   price: '56.95 zł',
  //   type:
  //     'Zestaw składa się z 3 posiłków - śniadanie , obiad i podwieczorek. Przygotowany z myślą o zapewnieniu zdrowego odżywiania w pracy.',
  //   jidel: '3 posiłków',
  // },
  {
    img: img7,
    title: 'BEZ GLUTENU i LAKTOZY',
    param1: '329 kcal',
    param2: '(6 694 kJ)',
    param3: '180g',
    price: '77.95 zł',
    type:
      'Dieta eliminująca produkty zawierające gluten i produkty mleczne. W naszym menu zastąpiliśmy je produktami zbożowymi naturalnie pozbawionymi glutenu oraz roślinnymi zamiennikami mleka. Program ten polecamy osobom mającym alergię lub nietolerancję pokarmową na mleko i jego przetwory, laktozę i gluten. Ze względu na brak możliwości całkowitej eliminacji glutenu program ten nie jest odpowiedni dla osób chorujących na celiakię.',
    jidel: '5 posiłków',
  },
  // {
  //   img: img6,
  //   title: 'WEGAŃSKA',
  //   param1: '329 kcal',
  //   param2: '(6 694 kJ)',
  //   param3: '180g',
  //   price: '77.95 zł',
  //   type:
  //     'Dieta wegańska eliminuje wszystkie produkty pochodzenia zwierzęcego takie jak: mięso, ryby, nabiał, jaja, miód. Dieta ta opiera się głównie na produktach roślinnych.',
  //   jidel: '5 posiłków',
  // },
  // {
  //   img: img8,
  //   title: 'HASHIMOTO',
  //   param1: '329 kcal',
  //   param2: '(6 694 kJ)',
  //   param3: '180g',
  //   price: '77.95 zł',
  //   type:
  //     'Dieta Hashimoto to plan odżywczy skomponowany dla osób ze zdiagnozowanym autoimmunologicznym zapaleniem tarczycy Hashimoto. Nasz program ma za zadanie wyciszyć procesy zapalne toczące się w organizmie, wspomóc leczenie farmakologiczne, dostarczyć odpowiednią ilość makroskładników, witamin i składników mineralnych wspierających prawidłowe funkcjonowanie organizmu. Dieta Hashimoto nie zawiera soi, glutenu i produktów mlecznych.',
  //   jidel: '5 posiłków',
  // },
  {
    img: img9,
    title: 'LOW IG',
    param1: '329 kcal',
    param2: '(6 694 kJ)',
    param3: '180g',
    price: '77.95 zł',
    type:
      'Low Ig to dieta z niskim indeksem glikemicznym, Idealnie sprawdza się u osób cierpiących na insulinooporność oraz cukrzycę typu 2. Eliminuje produkty wysokoprzetworzone , cukier oraz produkty o wysokim indeksie glikemicznym .',
    jidel: '5 posiłków',
  },
  // {
  //   img: img10,
  //   title: 'Ketogeniczna',
  //   param1: '329 kcal',
  //   param2: '(6 694 kJ)',
  //   param3: '180g',
  //   price: '81.95 zł',
  //   type:
  //     'Dieta Ketogeniczna charakteryzuje się długotrwałym niskim spożyciem węglowodanów (poniżej 50g dziennie w zależności od kaloryczności diety). Jej podstawę stanowią tłuszcze i umiarkowane ilości białka.',
  //   jidel: '4 posiłków',
  // },
]
export const CustomNextArrow = props => {
  const isMobile = window.innerWidth >= 1400
  const isLastElemet = props.onClick === null

  return (
    <div
      className={`${styles.next} ${isLastElemet ? styles.hidden : ''}`}
      onClick={props.onClick}
    >
      <img src={arrowImg} alt="Next" />
    </div>
  )
}

export const CustomPrevArrow = props => (
  <div
    className={`${styles.prev} ${
      props.currentSlide === 0 ? styles.hidden : ''
    }`}
    onClick={props.onClick}
  >
    <img src={arrowImg} alt="Previous" />
  </div>
)

export const NewCalcBlocks = () => {
  const { t } = useTranslation()

  const slides = slidesData?.map(item => (
    <div className={styles.perCarouselItem}>
      <NewFoodCardCalendar
        label={item.label}
        img={item.img}
        title={item.title}
        jidel={item.jidel}
        type={item.type}
        price={item.price}
      ></NewFoodCardCalendar>
    </div>
  ))

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 4,
          dots: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,

          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  }

  return (
    <div className={styles.flexingContainer}>
      <Container isWide={true}>
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          <span>Wybierz jeden </span> z naszych programów
          <p className={styles.aboutHeader}>
            Wybierz jeden z naszych programów dietetycznych i ciesz się
            najlepszym smakiem
          </p>
        </h3>
        <div className={styles.calendarWrapSlider}>
          <Slider className={styles.carouselList} slideCount={11} {...settings}>
            {slides}
          </Slider>
        </div>
      </Container>
    </div>
  )
}
