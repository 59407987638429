import React from 'react'
import { Container } from '../container'
import styles from './quality.module.css'
import image1 from './images/1.png'
import image2 from './images/2.png'
import image3 from './images/3.png'
import image4 from './images/4.png'
import image5 from './images/quality.png'
import image6 from './images/icon.png'
// import cx from 'classnames'
// import Slider from 'react-slick'
import { Trans } from 'react-i18next'

export const Quality = () => {
  const settings = {
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <section className={styles.quality}>
      <h3
        className="fancyUnderlineText sectionTitleNew text-center"
        style={{ marginBottom: 0 }}
      >
        <Trans>
          Jakość <span> premium </span> to Nasz standard
        </Trans>
      </h3>
      <Container isWide={true}>
        <div className={styles.parentBlok}>
          <div className={styles.textBlok}>
            <div className={styles.img}>
              <img src={image6} />
            </div>
            <div className={styles.mob}>
              <h5>Roman Siutkowski</h5>
              <p>Operations Director at NutritionPro</p>
            </div>
            <p>
              Nazywam się Roman Siutkowski i zapraszam Cię na profesjonalną
              przygodę ze zdrowym odżywianiem.{' '}
            </p>
            <p>
              Od ponad 20 lat związany jestem z branżą gastronomiczną, budując
              bardzo szerokie doświadczenie na wielu płaszczyznach pracy w
              sektorze gastronomicznym. W 2016 roku związałem się z cateringiem
              dietetycznym odkrywając swoją pasję do zdrowego stylu życia.{' '}
            </p>
            <p>
              Praca z Ludźmi i dla Ludzi , ciągłe doskonalenie jakości i
              usprawnianie procesów dostarczają mi pozytywnej energii i motywują
              do sięgania po więcej - z myślą o Waszym zdrowiu.
            </p>
            <div className={styles.desk}>
              <h5>Roman Siutkowski</h5>
              <p>Operations Director at NutritionPro</p>
            </div>
          </div>
          <div className={styles.imgBlock}>
            <img src={image5} />
          </div>
        </div>
        {/* <div className={styles.sliderBlok}>
       <Slider
          {...settings}
          className={cx('', styles.carouselList)}
        >
          <div className={styles.carouselItem}>
            <img src={image1} alt="icon" />
            <p className={styles.itemTitle}>Moderní výrobní plocha</p>
            <p>
              Používáme nejmodernější technologie k co nejšetrnějšímu zpracování
              surovin.
            </p>
          </div>

          <div className={styles.carouselItem}>
            <img src={image2} alt="icon" />
            <p className={styles.itemTitle}>Nejlepší dodavatelé</p>
            <p>Všichni naši dodavatelé jsou dlouhodobě prověřeni.</p>
          </div>

          <div className={styles.carouselItem}>
            <img src={image3} alt="icon" />
            <p className={styles.itemTitle}>Jen ty nejkvalitnější suroviny</p>
            <p>
              Každou jednotlivou dodávku kontrolujeme, aby se ke zpracování
              dostaly jen ty nejkvalitnější suroviny.
            </p>
          </div>

          <div className={styles.carouselItem}>
            <img src={image4} alt="icon" />
            <p className={styles.itemTitle}>Zkušení kuchaři</p>
            <p>
              Naši kuchaři vaří podle nejnovějších trendů a postupů, denně pro
              vás připravují až 5 000 pokrmů.
            </p>
          </div>
        </Slider>
       </div> */}
      </Container>
    </section>
  )
}
