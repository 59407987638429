import React, { useEffect } from 'react'
import styles from './modal.module.css'

const EscKey = 'Escape'
const modalClass = 'modal-open'

const useEscapeKeyDown = (props) => {
  const handleEscFunction = () => {
    props?.hideModal && props.hideModal()
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event?.key === EscKey) {
        event.preventDefault()
        handleEscFunction()
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])
}

const useModalOpen = () => {
  useEffect(() => {
    document.body.classList.add(modalClass)
    return () => {
      document.body.classList.remove(modalClass)
    }
  }, [])
}

export const Modal = (props) => {
  const { children } = props

  useEscapeKeyDown(props)
  useModalOpen()

  return (
    <>
      <div className={styles.modal}>{children}</div>
      <div className={styles.background} />
    </>
  )
}
